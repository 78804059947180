import React, { useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { FaPlus } from '@react-icons/all-files/fa/FaPlus'
import { FaMinus } from '@react-icons/all-files/fa/FaMinus'
import { useToggle } from 'hooks/use-toggle'
import { Collapse, COLLAPSE_DURATION } from 'components/collapse'
import UnstyledButton from 'components/button/unstyled-button'

const STATUS = {
  idle: 'IDLE',
  animating: 'ANIMATING',
}

const Accordion = ({ children, kind, title }) => {
  const [active, toggle] = useToggle(false)
  const [status, setStatus] = useState(STATUS.idle)

  const handleClick = () => {
    if (status === STATUS.animating) {
      return
    }

    toggle()
    setStatus(STATUS.animating)

    setTimeout(() => {
      setStatus(STATUS.idle)
    }, COLLAPSE_DURATION)
  }
  return (
    <AccordionContainer>
      <AccordionHeaderContainer active={active} onClick={handleClick} data-sc-kind={kind}>
        <HeaderTitleContainer>
          <HeaderTitle>{title}</HeaderTitle>
        </HeaderTitleContainer>
        <AccordionHeaderToggle>
          {active ? (
            <FaMinus aria-hidden='true' focusable='false' role='presentation' />
          ) : (
            <FaPlus aria-hidden='true' focusable='false' role='presentation' />
          )}
        </AccordionHeaderToggle>
      </AccordionHeaderContainer>
      <Collapse isOpened={active}>
        <CollapseInner data-sc-kind={kind}>{children}</CollapseInner>
      </Collapse>
    </AccordionContainer>
  )
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(['light', 'white']),
  title: PropTypes.string.isRequired,
}

Accordion.defaultProps = {
  kind: 'white',
}

const AccordionContainer = styled.div`
  border-top: 1px solid #d7d7d7;

  :last-child {
    border-bottom: 1px solid #d7d7d7;
  }
`

const AccordionHeaderContainer = styled(UnstyledButton)`
  align-items: center;
  background-color: ${props => {
    switch (props['data-sc-kind']) {
      case 'light':
        return props.active ? props.theme.color.n10 : props.theme.color.n20
      case 'white':
      default:
        return props.active ? props.theme.color.n10 : '#fff'
    }
  }};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 16px;
  position: relative;
  text-align: left;
  width: 100%;

  :hover,
  :focus {
    background-color: ${props => props.theme.color.n10};
    cursor: pointer;
  }

  svg {
    height: 0.9em;
    vertical-align: middle;
    width: 0.9em;
  }
`

const HeaderTitleContainer = styled.div`
  display: inline-block;
  flex-grow: 2;
  margin-right: 10px;
  vertical-align: middle;
  width: 80%;

  @media (min-width: 800px) {
    width: 90%;
  }
`

const HeaderTitle = styled.span`
  color: ${props => props.theme.color.n700};
  display: inline-block;
  font-size: 1.25em;
  font-weight: 600;
  margin: 0 8px 0 0;
  text-transform: none;
`

const AccordionHeaderToggle = styled.div`
  svg {
    color: ${props => props.theme.color.n400};
  }
`

const CollapseInner = styled.div`
  background-color: ${props => props.theme.color.n10};
  padding: 12px 16px 16px 16px;
`

export default Accordion
