import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import Checklist from 'components/new/checklist'
import Button from 'components/button/button'
import Accordion from 'components/new/accordion'
import Spacer from 'components/new/spacer'

const faqs = [
  {
    question:
      'If purchased from another individual or at a farm auction, can I still purchase PowerGard™?',
    answer: 'Contact us to find out if your machine is eligible.',
  },
  {
    question: 'Who do I purchase PowerGard™ from?',
    answer: 'PowerGard™ is only sold through John Deere dealers.',
  },
  {
    question: 'How do I know that my dealer did in fact purchase the PowerGard™?',
    answer:
      'John Deere mails out Contract Cards and Coverage Forms to the customer after PowerGard™ is purchased. However, if the customer’s address was incorrect or if the dealer did not submit any required certification the contract card may not have been received by the customer.',
  },
  {
    question:
      'I do not understand the coverage on my tractor? I wanted 3 years but my contract is only showing one more year!',
    answer:
      'If PowerGard™ is purchased while in warranty, the contract will include basic warranty. If 36 total months and 1500 total hours were purchased, there will be one year of PowerGard™ coverage or 1,500 hours of use, whichever comes first. ',
  },
  {
    question:
      'I am purchasing a different machine and need my PowerGard™ transferred from my previous machine to the new one. Is this transferrable?',
    answer: 'No, the PowerGard™ contract that was purchased stays with that unit.',
  },
  {
    question:
      'My machine just went out of basic warranty can I still get PowerGard™ coverage? If so how?',
    answer:
      'No, once a customer owned unit is beyond the basic warranty period, PowerGard™ is not available for that unit.',
  },
  {
    question:
      'My PowerGard™ contract is going to expire; can I buy additional coverage for my machine? ',
    answer:
      'Some products do have upgrade options, but may require certification. Contact us to find out if your machine is available for an upgrade.',
  },
  {
    question: 'Can I finance PowerGard™ when I finance my machine?',
    answer:
      'In general yes, you would have to contact your financial institution or dealer for verification.',
  },
  {
    question: 'Do I have to use a specific dealer for my PowerGard™ warranty work?',
    answer:
      'For most contracts any John Deere Dealer can perform the warranty repairs. If a Short Term used Tractor PowerGard™ Protection Plan contract was purchased, the dealer that sold the contract will have to perform the warranty repairs.',
  },
  {
    question: 'Do I have to pay the deductable for every issue that is wrong with my machine?',
    answer:
      'There is one deductible per covered event. If a work order has several segments or issues, there would be one deductible.',
  },
]

const PowergardPage = props => {
  const {
    data: { heroImage },
  } = props
  return (
    <Layout>
      <Helmet>
        <title>PowerGard™ Protection Plan | Hutson Inc</title>
        <meta
          name='description'
          content='With the PowerGard™ Protection Plan, you can purchase additional protection beyond the normal factory warranty term on new John Deere equipment. Get peace of mind knowing that you’re protected from unexpected repairs and your maintenance is kept up to date with genuine parts.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'FAQPage',
            'mainEntity': faqs.map(obj => ({
              '@type': 'Question',
              'name': obj.question,
              'acceptedAnswer': {
                '@type': 'Answer',
                'text': obj.answer,
              },
            })),
          })}
        </script>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='PowerGard™ Protection Plan'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <P>
          With the PowerGard™ Protection Plan, you can purchase additional protection beyond the
          normal factory warranty term on new John Deere equipment. Covered equipment includes: lawn
          tractors, zero-turn mowers, Gator utility vehicles, utility tractors, and commercial
          mowers. Get peace of mind knowing that you’re protected from unexpected repairs and your
          maintenance is kept up to date with genuine parts.
        </P>
        <Spacer />
        <H2>PowerGard™ Protection Plan for Residential Equipment</H2>
        <P>
          PowerGard™ Residential plans can be added to lawn and garden tractors, zero-turn mowers,
          Gator utility vehicles, compact utility tractors, utility tractors and commercial mowers.
          You can purchase PowerGard™ when you buy new equipment at our dealership or anytime before
          the factory warranty expires. View the{' '}
          <a href='/powergard/powergard-residential.pdf' target='_blank' rel='noopener noreferrer'>
            PowerGard™ Residential Plan Brochure
          </a>{' '}
          to learn more.
        </P>
        <Spacer />
        <H3>Why choose PowerGard™ Residential?</H3>
        <Spacer size='m' />
        <StyledChecklist>
          <>
            <strong>Flexible term lengths.</strong> Extend your factory warranty by up to 4 years.
            Choose the right fit for you based on how long you plan to own the equipment.
          </>
          <>
            <strong>Transportation covered.</strong> Get free transportation within our service area
            for repairs covered by factory warranty or extended repair protection.
          </>
          <>
            <strong>Simple claims process.</strong> No pre-approval necessary. Call or visit your
            local Hutson store for service.
          </>
          <>
            <strong>$0 deductible.</strong> No deductible and no out of pocket costs on covered
            repairs.
          </>
          <>
            <strong>Transferable.</strong> PowerGard™ can be transferred by the original purchaser
            for the remainder of the coverage.
          </>
          <>
            <strong>High-quality parts and service.</strong> Only Genuine John Deere parts specified
            for your machine will be used for covered repairs. All work will be completed by a
            certified John Deere technician.
          </>
        </StyledChecklist>
        <Spacer />
        <H3>Ready to add PowerGard™ Residential Protection?</H3>
        <P>
          Contact your local salesperson or visit{' '}
          <Link to='/locations/'>your nearest location</Link> for pricing and to see about adding
          PowerGard™ to your equipment. You can also fill out a contact form and we'll get it to the
          right person for you!
        </P>
        <Spacer size='s' />
        <Button color='green' ghost as={ButtonLink} to='/contact/'>
          Contact us
        </Button>
      </Content>
      <LightBackground>
        <Content kind='full'>
          <H2>PowerGard™ Protection Plan for Commercial Equipment</H2>
          <P>
            PowerGard™ Proection Plans are also avialable for new and used John Deere commercial
            equipment, including{' '}
            <a
              href='/powergard/powergard-utility-tractors.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Utility Tractors
            </a>
            ,{' '}
            <a
              href='/powergard/powergard-row-crop-4wd-tractors.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Row Crop and Four-Wheel Drive Tractors
            </a>
            ,{' '}
            <a href='/powergard/powergard-combines.pdf' target='_blank' rel='noopener noreferrer'>
              Combines
            </a>
            ,{' '}
            <a
              href='/powergard/powergard-cotton-harvesters.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Cotton Harvesters
            </a>
            ,{' '}
            <a
              href='/powergard/powergard-forage-harvesters.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Self-Propelled Forage Harvesters
            </a>
            ,{' '}
            <a href='/powergard/powergard-sprayers.pdf' target='_blank' rel='noopener noreferrer'>
              Sprayers
            </a>
            ,{' '}
            <a
              href='/powergard/powergard-commercial-mowers.pdf'
              target='_blank'
              rel='noopener noreferrer'
            >
              Commercial Mowers
            </a>
            ,{' '}
            <a href='/powergard/powergard-gators.pdf' target='_blank' rel='noopener noreferrer'>
              Gator Utility Vehicles
            </a>
            , and{' '}
            <a href='/powergard/powergard-ams.pdf' target='_blank' rel='noopener noreferrer'>
              AMS Components
            </a>
            .
          </P>
          <Spacer />
          <H3>Why choose PowerGard™ Commercial?</H3>
          <Spacer size='m' />
          <StyledChecklist>
            <>
              <strong>Multiple plan options.</strong> Choose from Limited Engine &amp; Powertrain or
              Comprehensive plans.
            </>
            <>
              <strong>Flexible term lengths.</strong> Extend your factory warranty by up to 6 years
              or by a your desired total hours. Choose the right fit for you based on how you plan
              to use the equipment.
            </>
            <>
              <strong>Simple claims process.</strong> No pre-approval necessary. Call or visit your
              local Hutson store for service.
            </>
            <>
              <strong>Low (or no) deductible.</strong> Save money with low (or no) deductibles.
            </>
            <>
              <strong>Transferable.</strong> PowerGard™ can be transferred by the original purchaser
              for the remainder of the coverage.
            </>
            <>
              <strong>High-quality parts and service.</strong> Only Genuine John Deere parts
              specified for your machine will be used for covered repairs. All work will be
              completed by a certified John Deere technician.
            </>
            <>
              <strong>Financing options.</strong> PowerGard™ Commercial can be financed.
            </>
          </StyledChecklist>
          <Spacer />
          <H3>PowerGard™ for Agriculture Equipment FAQs</H3>
          <Spacer size='m' />
          <div>
            {faqs.map(faq => (
              <Accordion title={faq.question} kind='light' key={faq.question}>
                <P>{faq.answer}</P>
              </Accordion>
            ))}
          </div>
        </Content>
      </LightBackground>
      <Content kind='full'>
        <H2>Ready to add PowerGard™ Protection?</H2>
        <P>
          Contact your local salesperson or visit{' '}
          <Link to='/locations/'>your nearest location</Link> for pricing and to see about adding
          PowerGard™ to your equipment. You can also fill out a contact form and we'll get it to the
          right person for you!
        </P>
        <Spacer size='s' />
        <Button color='green' ghost as={ButtonLink} to='/contact/'>
          Contact us
        </Button>
      </Content>
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`
const StyledChecklist = styled(Checklist)`
  @media (min-width: 800px) {
    columns: 2;
  }
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "powergard/powergard-hero.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default PowergardPage
